<template>
  <section class="image-slider">
    <UiHeader
      v-if="header"
      class="image-slider__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
    />

    <UiSwiper :options="swiperOptions">
      <SwiperSlide v-for="image in flatGallery" :key="image.id">
        <div class="image-slide">
          <UiImg :image="image" :width="320" class="image-slide__photo" />
        </div>
      </SwiperSlide>
    </UiSwiper>

    <div class="image-slider__controls">
      <button ref="prev" class="swiper-button swiper-button-prev" />
      <button ref="next" class="swiper-button swiper-button-next" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { Autoplay, Navigation } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import type { UiCeTextGalleryProps } from '~/types'
import { UiHeader, UiImg, UiSwiper } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'

const props = defineProps<UiCeTextGalleryProps>()

const flatGallery = useFlatGallery(props)

const next = ref(null)
const prev = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Navigation, Autoplay],
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    spaceBetween: 16,
    breakpoints: {
      1920: { slidesPerView: 6 },
      1440: { slidesPerView: 5 },
      1024: { slidesPerView: 4 },
      768: { slidesPerView: 3 },
      425: { slidesPerView: 2 },
      0: { slidesPerView: 1 }
    },
    navigation: {
      nextEl: next.value,
      prevEl: prev.value
    }
  })
)
</script>

<style lang="scss">
.image-slider {
  max-width: 1920px;
  margin-inline: auto;

  display: flex;
  align-items: center;
  flex-direction: column;

  & .swiper {
    width: 100%;

    &-slide {
      display: flex;
      width: 100%;
    }

    &-wrapper {
      padding-block: rem(40px);
    }

    &-navigation {
      position: absolute;
      inset: 0;
      top: auto;
      margin: auto;
      width: rem(20px);
    }
  }

  &__controls {
    display: flex;
    gap: rem(24px);
  }
}

.image-slide {
  width: 100%;

  &__photo {
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
